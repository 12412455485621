module services {
    export module applicationcore {
        export class classificationValueService implements interfaces.applicationcore.IClassificationValueService {
            static $inject = ["$resource", "ENV"];
            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Get Classification Value by Classification
            GetClassificationValueByClassification(): ng.resource.IResourceClass<interfaces.applicationcore.IClassificationValue> {
                var getDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return this.$resource<interfaces.applicationcore.IClassificationValue>(this.ENV.DSP_URL + "ClassificationValue/GetListByClassification", {
                        classificationId: '@classificationId'
                    }, {
                            query: getDetail
                        });
            }
            //Get Classification Value by Classification for a dropdown
            GetClassificationValueDropdownByClassification(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                var getDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ClassificationValue/GetDropdownListByClassification", {
                        classificationId: '@classificationId'
                    }, {
                            query: getDetail
                        });
            }
        }
    }
    angular.module("app").service("classificationValueService", services.applicationcore.classificationValueService);
}